$primary: #242424;
$secondary: #181818;
$inactive: #424242;
$body: #E7E7E7;
$md-gray: #999;
$dk-gray: #666;
$error: #d00000;
$link-blue: #7ac6ff;

.muted {
  color: $dk-gray; }
