html, body {
  font-size: 14px; }

h1, h2, h3, h4, h5, h6 {
  margin: 0px; }

h4 {
  font-weight: normal; }

h5 {
  font-size: 1.15rem;
  line-height: 1.3; }

h6 {
  text-transform: uppercase;
  font-size: 0.75rem; }

.bold {
  font-weight: bold; }
